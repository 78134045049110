// eslint-disable-next-line import/no-anonymous-default-export
export default {
  translation: {
    continue: 'CONTINUE',
    maybeLater: 'MAYBE LATER',
    signUpButton: 'SIGN UP',
    logInButton: 'LOG IN',
    uhOh: 'Uh Oh!',
    tryAgain: 'Sorry! Try Again',
    retry: 'RETRY',
    skipForNow: 'SKIP FOR NOW',
    gotIt: 'GOT IT!',
    goBack: 'GO BACK',
    leave: 'LEAVE',
    stay: 'STAY ON SCREEN',
    leaveFormModalDescription:
      'If you leave this screen you will lose the data you entered and you will have to start again',
    recoverPassword: {
      title: 'Recover your password',
      description:
        'To recover your password, please enter your email address or phone number. Reset instructions will be sent to you.',
      formInputs: {
        username: {
          label: 'Email Address or Phone Number',
          placeholder: 'Ej: hello@goalsetter.co or (999) 999 9999',
        },
      },
      errors: {
        tryAgain: {
          message:
            'We are sorry, we could not send the instructions. Please try again.',
        },
      },
      formErrors: {
        username: {
          wrong: 'Enter a valid Email Address or Phone Number',
        },
      },
      recoverPasswordModal: {
        title: 'Recover your Password',
        description:
          'If our records match the email or phone number provided, you ’ll receive an email or SMS with reset instructions. For help, please email us at',

        buttonLabel: 'GO TO LOGIN',
        contactEmail: 'hello@goalsetter.co',
      },
    },
    notAvailable: {
      title: 'Thank you for choosing Goalsetter',
      description:
        'At the moment, your account can only be used in the app. Sorry for the inconvenience.',
      downloadTheApp: 'DOWNLOAD THE APP',
    },
    sendGift: {
      mainTitle:
        '<parent>Send a GoalCard<span>®</span> to a Goalsetter<span>®</span> user</parent>',
      mainTitleDesktopFirst: '<parent>Send a GoalCard<span>®</span></parent>',
      mainTitleDesktopSecond:
        '<parent>to a Goalsetter<span>®</span> user</parent>',
      title: 'Who’s the lucky person?',
      subtitle:
        "The Goalsetter® user's exact Profile ID must be used, which they can find in their profile.",
      inviteLabel: 'Is your lucky person not a Goalsetter® user?',
      inviteButtonLabel: 'INVITE THEM!',
      notFound:
        "We did not find any user with that profile name. If the person you're looking for doesn't have a Goalsetter® account, you can invite them to join.",
      input: {
        placeholder: 'Enter profile name',
      },
      userProfileNotFound: {
        title: 'User Not Found',
        subtitle:
          'We did not find that profile. Please search for the user with their profile name.',
      },
    },
    footer: {
      about: 'ABOUT',
      contactUs: 'CONTACT US',
      goalCardTerms: 'GOALCARD® TERMS OF USE',
      rights:
        '© {{year}} All Rights Reserved. Goalsetter® is a registered trademark of Students of Wealth, Inc.',
    },
    downloadApp: {
      downloadAppButton: 'DOWNLOAD',
      downloadApp:
        'Download the Goalsetter® app now and enjoy a free 30-day trial.',
      scanQr:
        'Scan the QR code to download the Goalsetter® app now and enjoy a free 30-day trial.',
    },
    sendInvitation: {
      title: '<parent>Invite to join Goalsetter<rm>®</rm> !</parent>',
      sendInvitation: 'SEND INVITATION',
      formInputs: {
        yourName: {
          label: 'Your Name',
          placeholder: 'Enter your name',
        },
        theirName: {
          label: 'Their Name',
          placeholder: 'Enter the person you want to invite',
        },
        contact: {
          label: 'Email Address or Phone Number',
          placeholder:
            'Enter Email Address or Phone Number of the person you want to invite',
        },
      },
      googleSupport: 'This site is protected by reCAPTCHA and the Google ',
      privacyPolicy: 'Privacy Policy',
      and: ' and ',
      termsService: 'Terms of Services apply.',
      share: {
        title: 'You’re invited to join Goalsetter®!',
        text: 'Use this link to download the Goalsetter® app',
      },
      formErrors: {
        yourName: {
          empty: 'Enter your name',
          wrong: 'Can only contain letters',
        },
        theirName: {
          empty: 'Enter the person you want to invite',
          wrong: 'Can only contain letters',
        },
        contact: {
          empty:
            'Enter Email Address or Phone Number of the person you want to invite',
          wrong: 'Enter a valid Email Address or Phone Number',
        },
      },
      error: 'The invitation could not be sent, please try again.',
      errorAlready:
        "You've already sent this person an invite. Please wait a few minutes to send another invite again.",
      errorTryAgain: 'Something went wrong. Try again',
    },
    sentSuccess: {
      title: 'Invitation sent!',
      description:
        'We have sent an invitation to {{destinationName}} to join Goalsetter®. Once an account is created you will be able to send them a GoalCard®.',
      description_two:
        'In the meantime, you can send a GoalCard® to any Goalsetter® user you know.',
      sendGoalcard: 'SEND A GOALCARD®',
    },
    selectCard: {
      title: "Let's customize {{name}}'s GoalCard<span>{{reg}}</span>",
      description: 'Choose your GoalCard<span>{{reg}}</span> design',
      filterOptions: [
        {
          id: 0,
          value: 'all',
          label: 'All GoalCards®',
          icon: 'all',
        },
        {
          id: 1,
          value: 'birthday',
          label: 'Birthday',
          icon: 'birthday',
        },
        {
          id: 2,
          value: 'holiday',
          label: 'Holiday',
          icon: 'holiday',
        },
        {
          id: 3,
          value: 'milestone_moments',
          label: 'Milestone',
          icon: 'flag',
        },
        {
          id: 4,
          value: 'just_because',
          label: 'Just Because',
          icon: 'heart',
        },
      ],
      errorModalDescription:
        "We're having trouble connecting with your device. Please try again.",
    },
    sentGoalCardSuccess: {
      title:
        '<parent>You gave a ${{amount}} GoalCard<span>®</span> to support {{firstName}} goals!</parent>',
      description:
        'We sent an email confirmation to {{firstName}}. We will email you when the Goalcard is claimed.',
      sendAnother: 'SEND ANOTHER GOALCARD®',
    },
    resetPassword: {
      title: 'Change Password',
      newPass: 'New Password',
      repeatPass: 'Repeat New Password',
      reset: 'RESET PASSWORD',
      success: 'Your password was changed successfully!',
      info: 'You can use your new password to login to Goalsetter via our Android or iOS app, on any phone or mobile device.',
      tooEasy: 'Try a more difficult password',
      errorEmpty: 'Passwords cannot be empty.',
      errorLength:
        "Password must be minimum 8 characters long and contain mixed case letters, at least 1 number, and 1 symbol. It cannot contain the word 'Goalsetter'",
      errorMatch: 'Passwords do not match.',
      errorExpired:
        '\nIt seems your recovery password token has expired, please request another one using the Goalsetter app.',
      errorDescription:
        "\nWe're having trouble connecting your device to our server. Please try again, but if they keep refusing to play nicely, send us a note at help@goalsetter.co and we'll try to help.",
      errorPersonalData:
        'Please create a password that does not contain your profile name, email address, or phone number.',
    },
    resetPasswordSuccess: {
      title: 'Your Password was changed successfully!',
      description:
        'You can use your new password to login to Goalsetter via our Android or iOS app, on any phone or mobile device.',
    },
    customizeCard: {
      summarySectionTitle: 'Summary',
      customizeSectionTitle: 'Customized card',
      to: 'To',
      from: 'From',
      total: 'Total',
      goalCard: 'GoalCard<span>®</span>',
      enterAmount: 'Enter the amount',
      enterGoalCardAmount: 'Enter the amount for your GoalCard®',
      goalCardLabel: 'GoalCard®',
      amountLimit: 'The limit for a single transaction is $200',
      formInputs: {
        name: { label: 'Your Name', placeholder: 'Enter your Name' },
        email: {
          label: 'Your Email Address',
          placeholder: 'Enter your Email Address',
        },
        date: {
          label: 'Date',
          placeholder: 'MM/DD/YYYY',
        },
        whichGoalsTitle: 'Choose which goals to support',
        specialMessage: {
          label: 'Want to add a special message?',
          placeholder: 'Enter your Message',
        },
        checkBoxInputs: {
          howToSupport: {
            title: 'Choose how to support',
            giveSpecificGoal: "Give to {{name}}'s specific goal(s)",
            letChoose: 'Let {{name}} choose how to spend their GoalCard®',
          },
          when: {
            title: 'When should we send the card?',
            rightNow: 'Right now!',
            specificDate: 'On a specific date',
          },
        },
        formErrors: {
          name: {
            empty: 'Enter a Name',
            wrong: 'Can only contain letters',
          },
          email: {
            empty: 'Enter an Email Address',
            wrong: 'Enter a valid Email Address',
          },
          date: {
            empty: 'Enter a Date',
            wrong: 'Enter a valid Date',
          },
        },
      },
      uploadPhoto: {
        title: 'Add a photo or video',
        dropYourMedia: 'Drop image or video here',
        selectFile: 'SELECT FILE',
        valids: 'Valid format: png, jpg, or jpeg, mp4',
        cancelButton: 'CANCEL',
        continueButton: 'CONTINUE',
        editorInstructions: '',
        chooseAnother: 'CHOOSE ANOTHER PHOTO OR VIDEO',
        errorMedia: 'Please provide a supported format',
      },
      addPhoto:
        'Add some spice with a photo or video (optional)<br><span>Valid format:</span>  png, jpg, or jpeg, mp4.',
      emptyGoalCardError:
        'You cannot send a $0 GoalCard®. Enter a valid amount an try again.',
    },
    purchase: {
      title: "You're almost done.",
      orderSummary: 'Order Summary',
      orderPayment: 'Order Payment',
      cardTotal: 'Card total',
      serviceFee: 'Goalsetter® service fee',
      submitPayment: 'SUBMIT PAYMENT',
      formInputs: {
        cardHolder: {
          label: 'Cardholder full name',
          placeholder: 'Enter Cardholder full name',
        },
        cardNumber: {
          label: 'Card details (debit or credit)',
          placeholder: 'Enter Card number',
        },
        mmyy: {
          placeholder: 'MM/YY',
        },
        cvc: {
          placeholder: 'CVC',
        },
        zipCode: {
          placeholder: 'Zip Code',
        },
        formErrors: {
          cardHolder: {
            empty: 'Enter Cardholder Name',
            wrong: 'Can only contain letters',
          },
          cardNumber: {
            empty: 'Enter a Card Number',
            wrong: 'Enter a valid Card Number',
          },
          mmyy: {
            empty: 'Enter a date',
            wrong: 'Incorrect date',
          },
          cvc: {
            empty: 'Enter CVC number',
            wrong: 'Incorrect CVC',
          },
          zipCode: {
            empty: 'Enter a Zip Code',
            wrong: 'Incorrect Zip Code',
          },
        },
      },
      stripeErrorModal: {
        balanceInsufficient: {
          description:
            'Your card has insufficient funds. Please select a new payment method.',
          buttonLabel: 'TRY WITH ANOTHER CARD',
        },
        authenticationFailure: {
          description: 'Please select a new payment method.',
          buttonLabel: 'RETRY WITH ANOTHER CARD',
        },
        unsopported: {
          description: 'Your card does not support this type of purchase.',
          buttonLabel: 'RETRY WITH ANOTHER CARD',
        },
        generic: {
          description: 'Your payment could not be processed. Please try again.',
          buttonLabel: 'TRY AGAIN',
        },
      },
      terms:
        'By making a purchase, you accept the <span>Stripe Terms of Service</span>',
    },
    profile: {
      title: 'Profile',
      buttonLabel: '<parent>GIVE A GOALCARD<span>{{reg}}</span></parent>',
      goalNotFound: {
        title: 'Goal Not Found',
        description:
          "We couldn't find the goal you are looking for. But don't worry, you can still send a Goalcard®.",
      },
      profileDetailCard: {
        isSavingFor: 'is saving for',
        giveToGoal: 'GIVE TO GOAL',
        savedToward: 'Saved toward goal',
        noEndDate: 'No end date',
        supportButtonLabel: 'SUPPORT THIS GOAL',
        beTheFirst: 'Be the first supporter!',
        goalFacts: 'Goal Facts:',
        goalFactsDescription:
          "$1 billion was wasted on gifcards last year alone. Aren't you happy you can give a GoalCard® instead?",
      },
    },
    notFound: {
      title: 'Uh-oh...',
      subtitle: "We couldn't find the page you are looking for.",
      troubleshooting:
        "We're troubleshooting this issue on our end. Thank you for your patience as we work on finding a solution.",
      inTheMeantime:
        'In the meantime, please feel free to reach out to us at <span>{{email}}</span> with questions or suggestions.',
    },
    animatedGoalCard: {
      goalcard: 'GOALCARD',
      goalcardTo: 'To',
      gotMoney: "You've Got Money!",
      goalcardGreeting: 'Hi there {{name}},',
      goalcardFrom: 'From',
      goalcardFor: 'For',
      errorModal: {
        errorTryAgain: 'Something went wrong. Try again',
      },
    },
    printGoalcard: {
      to: 'To',
      from: 'From',
      for: 'For',
      yourGoals: 'YOUR GOALS',
      hi: 'Hi',
      whatIs: 'WHAT IS GOALSETTER?',
      descriptionTitle:
        'Goalsetter is the best savings and gifting platform to help kids save for their dreams.',
      howToRedeem: 'HOW TO REDEEM:',
      goalcardReceivetoTitle: "You'll receive a copy of your Goalcard to ",
      goalcardSentToTitle: "We've sent a copy of your Goalcard to ",
      goalcardClaimInfo:
        '. Just click on the card and set up a Goalsetter account to redeem the Goalcard and start saving towards your dreams.',
      goalcardClaimInfo2:
        ' on {{executeAtDate}}. When you receive it, just click on the card and set up a Goalsetter account to redeem the Goalcard and start saving towards your dreams.',
      gotMoney: "You've Got Money!",
      replayBtn: 'REPLAY',
      claimBtn: 'CLAIM GIFT',
      printBtn: 'PRINT',
    },
    downloadAppModal: {
      title: 'Download the Goalsetter app and start saving today!',
      subtitle:
        'In order to claim this Goalcard, <span>please scan this QR</span> code to download the app. Goalsetter is available for both iOS and Android phones and tablets.',
    },
    userNotAbleToReceiveMoneyModal: {
      title: 'This user is not set up to receive money',
      subtitle:
        'This user is still setting up their account and will not be able to receive a GoalCard until completed. Please check with them and try again when completed.',
    },
    underConstruction: {
      title: 'Goalsetter GoalCard®',
      subtitle: 'Under Construction!',
      description:
        "We are updating our offering and have temporarily suspended the ability to send a GoalCard via our website.  You can still send GoalCards via the Goalsetter App. If you have any questions or would like to be notified when it's available, please email us at <span>hello@goalsetter.co</span>.",
    },
  },
};
